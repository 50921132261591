// <copyright file="routes.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import AccountSettingsView from '@/components/users/AccountSettingsView.vue';
import ClientDownload from '@/components/help/ClientDownload.vue';
import CreateProjectView from '@/components/projects/create/CreateProjectView.vue';
import DashboardView from '@/components/dashboard/DashboardView.vue';
import DeviceListView from '@/components/devices/DeviceListView.vue';
import ForgotPasswordView from '@/components/login/ForgotPasswordView.vue';
import HelpPageView from '@/components/help/HelpPageView.vue';
import InvitationView from '@/components/login/InvitationView.vue';
import LoginView from '@/components/login/LoginView.vue';
import NotFoundView from '@/components/views/NotFoundView.vue';
import ProjectFileAnalysisView from '@/components/files/analysis/ProjectFileExaminationView.vue';
import ProjectFilesView from '@/components/files/ProjectFilesView.vue';
import ProjectSummaryView from '@/components/projects/summary/ProjectSummaryView.vue';;
import ProjectsView from '@/components/projects/ProjectsView.vue';
import ResetPasswordView from '@/components/login/ResetPasswordView.vue';
import UnauthorizedView from '@/components/views/UnauthorizedView.vue';
import UsersView from '@/components/users/UsersView.vue';
import { AuthorizedRouteConfig, IAuthorizedRoute } from '@/types/router.types';
import { paths } from '@/router/route-paths';
import { Permission } from '@/types/resource.types';

const routeConfigs: Record<string, AuthorizedRouteConfig> = {

    [paths.HOME]: {
        path: paths.HOME,
        name: 'Dashboard',
        component: DashboardView,
        meta: {
            breadcrumbIcon: 'mdi-apps',
            permission: Permission.Public
        },
        menuName: 'Dashboard',
        menuIcon: 'apps'
    },

    [paths.PROJECTS]: {
        path: paths.PROJECTS,
        name: 'Projects',
        component: ProjectsView,
        meta: {
            parent: paths.HOME,
            permission: Permission.ReadProjects
        },
        menuName: 'Projects',
        menuIcon: 'fingerprint'
    },

    [paths.PROJECT_CREATE]: {
        path: paths.PROJECT_CREATE,
        name: 'New Project',
        component: CreateProjectView,
        meta: {
            parent: paths.PROJECTS,
            permission: Permission.EditProjects
        }
    },

    [paths.PROJECT_DRAFT(':id')]: {
        path: paths.PROJECT_DRAFT(':id'),
        name: 'Create Project',
        component: CreateProjectView,
        meta: {
            parent: paths.PROJECTS,
            permission: Permission.EditProjects
        },
        props: (route: IAuthorizedRoute) => ({
            projectId: parseInt(route.params.id)
        })
    },

    [paths.PROJECT_SUMMARY(':id')]: {
        path: paths.PROJECT_SUMMARY(':id'),
        name: 'Project Summary',
        component: ProjectSummaryView,
        meta: {
            parent: paths.PROJECTS,
            permission: Permission.ReadProjects
        },
        props: (route: IAuthorizedRoute) => ({
            projectId: parseInt(route.params.id)
        })
    },

    [paths.FILES]: {
        path: paths.FILES,
        name: 'Files',
        component: ProjectFilesView,
        meta: {
            parent: paths.HOME,
            permission: Permission.ReadFiles
        },
        menuName: 'Files',
        menuIcon: 'folder-open'
    },

    [paths.FILE_ANALYSIS(':id')]: {
        path: paths.FILE_ANALYSIS(':id'),
        name: 'File Examination Result',
        component: ProjectFileAnalysisView,
        meta: {
            parent: paths.FILES,
            permission: Permission.ReadFiles
        },
        props: (route: IAuthorizedRoute) => ({
            fileId: parseInt(route.params.id)
        })
    },

    [paths.USERS]: {
        path: paths.USERS,
        name: 'User Management',
        component: UsersView,
        meta: {
            parent: paths.HOME,
            permission: Permission.ReadGroupUsers
        },
        menuName: 'Manage Users',
        menuIcon: 'account-multiple'
    },

    [paths.ACCOUNT_SETTINGS]: {
        path: paths.ACCOUNT_SETTINGS,
        name: 'Account Settings',
        component: AccountSettingsView,
        meta: {
            parent: paths.HOME,
            permission: Permission.Public
        },
        menuName: 'My Account Settings',
        menuIcon: 'cog'
    },

    [paths.HELP]: {
        path: paths.HELP,
        name: 'Support Request',
        component: HelpPageView,
        meta: {
            parent: paths.HOME,
            permission: Permission.Public
        },
        menuName: 'Support',
        menuIcon: 'chat-question'
    },

    [paths.DEVICE_LIST]: {
        path: paths.DEVICE_LIST,
        name: 'Reference Library Inventory',
        component: DeviceListView,
        meta: {
            parent: paths.HOME,
            permission: Permission.Public
        },
        menuName: 'Reference Library Inventory',
        menuIcon: 'cellphone'
    },
    [paths.CLIENT_DOWNLOAD]: {
        path: paths.CLIENT_DOWNLOAD,
        name: 'Download Verify Client',
        component: ClientDownload,
        meta: {
            parent: paths.HOME,
            permission: Permission.Public
        },
        menuName: 'Desktop Client',
        menuIcon: 'xml'
    },

    [paths.LOGIN]: {
        path: paths.LOGIN,
        name: 'Login',
        component: LoginView,
        meta: {
            permission: null
        }
    },

    [paths.FORGOT_PASSWORD]: {
        path: paths.FORGOT_PASSWORD,
        name: 'Forgot Password',
        component: ForgotPasswordView,
        meta: {
            permission: null
        }
    },

    [paths.RESET_PASSWORD]: {
        path: paths.RESET_PASSWORD,
        name: 'Reset Your Password',
        component: ResetPasswordView,
        meta: {
            permission: null
        }
    },

    [paths.INVITE]: {
        path: paths.INVITE,
        name: 'Invitation',
        component: InvitationView,
        meta: {
            permission: null
        }
    },

    [paths.UNAUTHORIZED]: {
        path: paths.UNAUTHORIZED,
        name: 'Unauthorized',
        component: UnauthorizedView,
        meta: {
            permission: Permission.Public
        }
    },

    // "Not Found" fallback
    [paths.WILDCARD]: {
        path: paths.WILDCARD,
        name: '404 Not Found',
        component: NotFoundView,
        meta: {
            permission: Permission.Public,
            parent: paths.HOME
        }
    },

};

const routes = Object.values(routeConfigs);

export { routeConfigs };
export default routes;
