
import authOidService from '@/services/AuthOidService';
import authService from '@/services/AuthService';
import MainNavigationItem from './MainNavigationItem.vue';
import routes from '@/router/routes';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { createMenuItemsFromRoutes } from '@/utils/router.utils';
import { Get } from 'vuex-pathify';
import { IMenuItem } from '@/types/router.types';
import { ISubscriber, IUser } from '@/types/resource.types';
import { paths } from '@/router/route-paths';

@Component({
    name: 'MainNavigation',
    components: {
        MainNavigationItem
    }
})
export default class MainNavigation extends Vue {

    @Get('user')
    user: IUser;
    @Get('subscriber')
    subscriber: ISubscriber;

    get nameFirstLetter(): string {
        let name = this.user?.firstName ?? '';
        return name.slice(0, 1);
    }

    get menuItems(): IMenuItem[] {
        return createMenuItemsFromRoutes(routes, this.user, this.subscriber);
    }

    /* Data
    ============================================*/

    menuIsCollapsed: boolean = true;
    menuIsOpen: boolean = true;
    userGuideMenuItem: IMenuItem = {
        icon: 'file-document',
        path: '',
        label: 'User Guide',
        externalUrl: 'https://docs.magnetforensics.com/docs/verify/help-verify-user/Content/en-us/verify/introduction/Home.htm'
    }

    /* Methods
    ============================================*/

    goHome(): void {
        this.$router.push(paths.HOME);
    }

    async logout(): Promise<void> {
        this.$loading = true;
        try {
    	    let x = await authService.signOut();
            await authOidService.logout();
            //console.log(x);
        } catch(e) {
            this.$logger.logError(e);
        }
    	//window.location.href = paths.HOME;
    }

    public toggle() {
        this.menuIsOpen = !this.menuIsOpen;
    }

}

