
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { FileAquisitionMethod } from '@/types/resource.types';

@Component({
    name: 'AquisitionMethodSelector',
})
export default class AquisitionMethodSelector extends Vue {
    buttons: AquisitionTypeButton[] = [
        {
            disabled: false,
            icon: 'upload',
            iconColor: 'black',
            text: 'Direct Upload',
            value: FileAquisitionMethod.DirectUpload
        },
        {
            disabled: false,
            icon: 'xml',
            iconColor: 'black',
            text: 'Verify Client Upload',
            value: FileAquisitionMethod.MetadataUpload
        }
    ];
}

export type AquisitionTypeButton = {
    disabled: boolean,
    icon: string,
    iconColor: string,
    text: string,
    value: FileAquisitionMethod
};
