import { IFileExaminationReportOptions } from '@/types/resource.types';

export default class FileExaminationReportOptions implements IFileExaminationReportOptions {
    includeStructureAnalysis = true;
    includeFileComments = true;
    includeProjectInformation = true;
    includeFileSummary = true;
    includeSimilarityAnalysisResult = true;
    includeModificationTests = true;
    includeValidationTests = true;
    includeFileNotes = true;
    includeFileMetadata = true;
    includeStructuralData = true;
    includeProprietaryData = true;
    includeUnknownData = true;
    includeMedexInsights = true;
    includeC2PA = true;
}
