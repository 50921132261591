// <copyright file="vuetify.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import Vue, { PluginObject } from 'vue';
import Vuetify, { VBtn, VIcon, VSnackbar } from 'vuetify/lib';
import VuetifyConfirm from 'vuetify-confirm';
import VuetifyToast from './VuetifyToast';

// TipTap Text Editor
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';

// Inspired by https://coreui.io/vue/demo/3.2.0/legacy/#/theme/colors
const light = {
    primary: '#0028B4', // blue
    secondary: '#c8ced3', // grey
    accent: '#2f353a', // dark color
    error: '#f86c6b', // red
    info: '#63c2de', // light blue
    success: '#4dd35e', // green
    warning: '#ff9e07', // yellow
};

const dark = {
    primary: '#4638c2',
    secondary: '#4c4f54',
    accent: '#0e0e15',
    error: '#d16767',
    info: '4799eb',
    success: '#45a164',
    warning: '#e1a82d'
};

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});

const vuetify = new Vuetify({
    theme: {
        themes: {
            light,
            dark
        }
    },
});

Vue.use(VuetifyToast, { $vuetify: vuetify.framework });
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(TiptapVuetifyPlugin as unknown as PluginObject<any>, {
    vuetify,
    iconsGroup: 'mdi'
});

export default vuetify;
